@use "../../mixin.scss" as *;
@use "../../variable.scss" as *;

.dont-delay {
  background: $last-section-gradient;
  padding: 81px 0 94px;
  margin-bottom: 67px;
  @include media991() {
    margin-bottom: 0;
    padding: 14px 0 37px;
  }
  .wrapper {
    display: flex;
    justify-content: space-between;
    @include media991() {
      flex-direction: column-reverse;
      align-items: center;
    }
    .star-rating {
      display: flex;
      align-items: center;
      span {
        font-weight: 700;
        margin-left: 10px;
        @include media991() {
          font-size: 14px;
        }
      }
    }
    .dont-delay__left {
      max-width: 668px;
      color: $dark-color;
      h1 {
        font-size: 40px;
        line-height: 130%;
        margin: 30px 0;
        font-family: $primary-font-700;
        @include media991() {
          font-size: 22px;
          margin: 16px 0;
        }
      }
      p {
        font-size: 20px;
        margin-bottom: 20px;
        line-height: 140%;
        color: $sub-dark-color;
        @include media991() {
          font-size: 14px;
        }
      }
    }
    .dont-delay__cta {
      display: flex;
      justify-content: space-between;
      padding-right: 96px;
      @include media991() {
        flex-direction: column;
        padding-right: 0px;
      }
      .company-info {
        @include media991() {
          font-size: 12px;
        }
        .call-flex {
          display: flex;
          font-size: 18px;
          font-weight: 600;
          @include media991() {
            font-size: 12px;
            margin-top: 24px;
          }
          .call-n {
            @include media991() {
              max-width: 14px;
              img {
                width: 100%;
              }
            }
          }
        }
        p {
          font-size: 14px;
        }
      }
    }
    .review-link {
      padding: 0;
      margin: 0 24px 0 0;
      @include media991() {
        margin-right: 0;
      }
      a {
        padding: 8px 29.5px;
        @include media991() {
          padding: 6.5px 20px;
        }
      }
    }

    .dont-delay__image {
      position: relative;

      .bag {
        max-width: 420px;
        @include media991() {
          margin: 0 auto;
        }
        img {
          width: 100%;
        }
      }
      .relative {
        position: relative;
        @include media991() {
          margin-top: 50px;
        }
        @include media575() {
          margin-top: 10px;
        }
        @include media475() {
          margin-top: 40px;
        }
      }
      .review-card {
        position: absolute;
        bottom: 40px;
        right: 0;
        background-color: $white-color;
        padding: 17.51px 24px;
        @include media1199() {
          bottom: 0;
        }
        @include media991() {
          background-color: $white-color;
          left: 0;
          font-size: 14px;
          line-height: 21.7px;
        }
      }
      .review-name-flex {
        display: flex;
        align-items: center;
        color: $black-color;
        min-width: 531px;
        @include media1250() {
          min-width: 400px;
        }
        @include media1199() {
          min-width: 350px;
        }
        @include media991() {
          min-width: 90%;
          margin-top: 5px;
        }
        .name {
          font-size: 14px;
          font-weight: bold;
        }
        figure {
          margin: 0 5px 0 20px;
        }
        .verified {
          font-size: 12px;
          margin-left: 5px;
        }
      }
      .reviewer-image {
        position: absolute;
        right: -30px;
        top: -60px;
        @include media1250() {
          right: -10px;
        }
        @include media991() {
          right: 0px;
          top: -35px;
        }
        figure {
          max-width: 97px;
          img {
            width: 100%;
          }
          @include media991() {
            max-width: 71px;
          }
        }
      }
    }
  }
}
