@use "../../mixin.scss" as *;
@use "../../variable.scss" as *;

.faq {
  background-color: #fcfcfc;
  padding: 50px 0;
  &__section {
    margin-top: 50px;

    &-card {
      &-question {
        display: flex;
        justify-content: space-between;
      }
      &-answer {
        display: flex;
      }
    }
  }
}

.accordion-item {
  overflow: hidden;
  border: 1.5px solid #b6b6b6;
  border-bottom: none;

  &:first-child {
    border-radius: 15px 15px 0 0;
  }
  &:last-child {
    border-bottom: 1.5px solid #b6b6b6;

    border-radius: 0 0 15px 15px;
  }
  .accordion-label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    padding: 26px 0 23px;
    transition: background-color 0.3s;
    .accordion-question {
      font-size: 22px;
      max-width: 80%;
      text-align: left;
      font-weight: 700;
      display: flex;
      color: $dark-color;
      font-family: $primary-font-700;
      @include media991() {
        font-size: 16px;
      }
      .number {
        margin: 0 52px 0 60px;
        font-size: 28px;
        color: #9d96a8;
        font-weight: 700;
        @include media991() {
          margin: 0 16px 0 20px;
          font-size: 16px;
        }
      }
    }
    .icon {
      margin-right: 60px;
      width: 48px;
      height: 48px;
      border-radius: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: 0.5s;
      background-color: #fafafa;
      @include media575() {
        margin-right: 11px;
        width: 32px;
        height: 32px;
      }
    }
  }

  .accordion-content {
    max-height: 0;
    overflow: hidden;
    background: $background-color;
    font-size: 0.95em;
    transition: max-height 0.5s ease, padding 0.5s ease;
    text-align: left;
    padding: 0 34px 0 148px;
    color: $sub-dark-color;
    @include media991() {
      padding: 0 21px 0 55px;
    }
    p {
      font-size: 20px;
      margin-top: 8px;
      max-width: 924px;
      line-height: 140%;
      @include media991() {
        font-size: 14px;
        margin-top: 16px;
      }
    }
    .accordion-flex {
      display: flex;
      justify-content: space-between;
      @include media575() {
        flex-direction: column-reverse;
      }
      figure {
        min-width: 115px;
        height: 98px;
        margin-top: 10px;
        @include media575() {
          max-width: 115px;
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 4px;
        }
      }
    }
  }

  &.active {
    .icon {
      background-color: $black-color;
      transform: rotate(45deg);
      .plus-icon {
        figure > img {
          display: flex;
          align-items: center;
          justify-content: center;
          filter: invert(100%);
        }
      }
    }
    .number {
      color: $dark-color !important;
    }
    .accordion-label {
      font-weight: bold;
      background-color: $primary-color;
      transition: 0;
      padding: 26px 0 2px;
    }

    .accordion-content {
      transition: padding 0.5s;
      background-color: $primary-color;
    }
  }
}
