@use "../../mixin.scss" as *;
@use "../../variable.scss" as *;

.footer {
  background-color: $dark-color;
  position: relative;
  padding: 111px 0 53px;
  @include media991() {
    padding: 0 0 32px;
  }
  .relative-footer {
    position: relative;
  }

  a {
    text-decoration: none;
  }
  &__logo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include media991() {
      flex-direction: column;
    }
    &-main {
      background-color: $white-color;
      align-items: center;
      padding: 20px 111px 20px 123px;
      border-radius: 0 8px 8px 0;
      font-family: $secondary-font;
      a {
        display: flex;
        align-items: center;
      }

      div h1 {
        line-height: 26px;
        font-size: 24px;
        color: $header-font-color;
        span {
          display: block;
        }
        @include media991() {
          font-size: 12px;
        }
        @include media767() {
          max-width: 100px; 
          line-height: 14px; 
        }
      
        @include media575() {
          font-size: 12px;
          line-height: 12px;
          max-width: 100%;
        }
        @include media375() {
          font-size: 3vw;
          max-width: 100px;
          line-height: 14px !important;
  
        }
      }
      div p {
        color: #2e3a58;
        font-size: 10px;
        margin-top: 3px;
        @include media991() {
          font-size: 6px;
        }
      }
      @include media991() {
        padding: 11px 32px;
        border-radius: 0 0 6px 6px;

        figure {
          max-width: 35px;
          img {
            width: 100%;
          }
        }
      }
    }

    &-company {
      background-color: $white-color;
      padding: 20px 157px 20px 40px;
      border-radius: 6px 0 0 6px;
      line-height: 130%;
      font-size: 14px;
      font-weight: 600;
      text-align: right;
      letter-spacing: 2%;
      a {
        text-decoration: underline;
        color: $black-color;
      }
      @include media991() {
        padding: 10px 0;
        text-align: center;
        border-radius: 0;
        width: 100%;
        margin: 21px 0;
        font-size: 12px;
      }
    }
  }

  &__desc {
    margin-top: 31px;
    color: #ebebeb;
    font-size: 12px;
    @include media991() {
      margin-top: 0;
    }
    &-one {
      display: flex;
      justify-content: space-between;
      margin-bottom: 32px;
      @include media991() {
        display: block;
        margin-bottom: 0;
      }
      p {
        &:first-child {
          max-width: 462px;
          @include media991() {
            margin-bottom: 10px;
          }
        }
        &:last-child {
          max-width: 659px;
        }
      }
    }
    &-other {
      p {
        margin: 12px 0;
        &:last-child {
          margin: 0;
        }
      }
    }
  }
}

.copy-right {
  text-align: center;
  background-color: $primary-color;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8.5px 0 8.5px;
  color: $dark-color;

  @include media991() {
    flex-direction: column;
    padding: 9.5px 0;
  }
  .mob-flex {
    display: flex;
    align-items: center;
  }
  span {
    font-size: 14px;
  }
  span > a {
    color: $dark-color;
    text-decoration: none;
  }
  .vr {
    width: 1px;
    height: 15px;
    background-color: $black-color;
    margin: 2px 10px 0 10px;
    &:first-child {
      @include media991() {
        display: none;
      }
    }
  }
}
