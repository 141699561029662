@use "../../mixin.scss" as *;
@use "../../variable.scss" as *;

.discover-elig {
  padding: 80px 0;

  &__first {
    display: flex;
    position: relative;
    @include media991() {
      flex-direction: column;
      align-items: center;
      max-width: 100%;
      margin: 0 auto;
    }

    .old-market {
      max-width: 623px;
      height: 308px;
      margin-right: 53px;
      @include media991() {
        margin-right: 0;
        max-width: 535px;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 16px;
      }
    }
    .review_one {
      position: absolute;
      right: 8vw;
      bottom: -80px;
      @include media991() {
        width: 100%;
        max-width: 535px;
        position: relative;
        bottom: -40px;
        right: 10px;
      }
      .review-wrap {
        position: relative;
        padding: 12px 132px 12px 24px;
        border-radius: 12px;
        background-color: $white-color;
        @include media991() {
          padding: 12px 20px 12px 20px;
        }
        .small-flex {
          display: flex;
        }
      }
      .text-p {
        font-size: 19px;
        font-weight: 700;
        color: $dark-color;

        @include media991() {
          font-size: 16px;
        }
      }
      .img {
        position: absolute;
        top: -20px;
        right: -20px;
        @include media991() {
          top: -10px;
          right: -10px;
        }
        figure {
          max-width: 56px;
          height: 56px;
          @include media991() {
            max-width: 62px;
            height: 62px;
          }
          img {
            width: 100%;
            height: 100%;
            border: 1px solid $background-color;
            border-radius: 50px;
          }
        }
      }
    }
    &-details {
      max-width: 510px;
      color: $dark-color;
      @include media991() {
        max-width: 535px;
      }

      h2 {
        font-size: 40px;
        line-height: 130%;
        font-family: $primary-font-700;
        @include media991() {
          font-size: 22px;
          margin-top: 16px;
        }
      }
      p {
        font-size: 20px;
        line-height: 140%;
        margin-top: 20px;
        font-weight: 500;
        @include media991() {
          font-size: 14px;
        }
      }
      .mt {
        margin-top: 10px;
      }
    }
  }

  &__second {
    margin-top: 50px;
    display: flex;
    justify-content: space-between;
    @include media991() {
      flex-direction: column;
      align-items: center;
    }
    &-review {
      background: $primary-gradient;
      padding: 24px 20px 0 24px;
      border-radius: 16px;
      max-width: 398px;
      margin: 0 25px;
      @include media991() {
        margin: 24px 0;
        padding: 20px 0 0 0;
        min-width: 525px;
      }
      @include media575() {
        min-width: 100%;
      }
      @include media350() {
        min-width: 100%;
      }
      .user {
        display: flex;
        align-items: center;
        @include media991() {
          padding: 0 20px;
        }
        .up {
          max-width: 44px;
          height: 44px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .user-details div {
          display: flex;
          align-items: center;
          margin-left: 15px;
          margin-bottom: -5px;
          color: $dark-color;

          .user-name {
            font-size: 14px;
            font-weight: 600;
            @include media350() {
              font-size: 12px;
            }
          }
          .blue-tick {
            max-width: 11px;
            margin: 0 2px 0 10px;

            img {
              width: 100%;
              height: 100%;
            }
          }
          .verified {
            font-size: 12px;
          }
        }
      }
      .review-text {
        color: $dark-color;
        padding: 0 86px 0 0;
        line-height: 22px;
        margin-top: 20px;
        margin-bottom: 22px;
        @include media991() {
          padding: 0 20px;
          max-width: 240px;
          font-size: 14px;
        }
      }
      .mobile-details {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: $white-color;
        font-size: 14px;
        margin-top: 30px;
        padding: 0 14px;
        border-radius: 6px 6px 0 0;
        @include media991() {
          font-size: 12px;
        }
        .number {
          display: flex;
          align-items: center;
        }
        .details p {
          line-height: 25px;
        }
      }
    }
    .one,
    .two {
      max-width: 398px;
      height: 307px;
      border-radius: 16px;
      @include media991() {
        min-width: 525px;
        height: 244px;
      }
      @include media575() {
        min-width: 100%;
      }
      @include media350() {
        min-width: 100%;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 16px;
      }
    }
  }
}
