@use "../../mixin.scss" as *;
@use "../../variable.scss" as *;

.mini-bg {
  background-color: $background-color;
}
.banner {
  background-color: $background-color;
  padding: 10px 0 40px 0;
  position: relative;
  width: 100%;
  overflow: hidden;
  @include media1500() {
    padding: 23px 0 0 0;
  }
  @include media991() {
    padding: 23px 0;
    max-width: 600px;
    margin: 0 auto;
  }
  &__header {
    position: relative;

    .break-header {
      display: block;
      @include media991() {
        display: inline;
      }
    }
    h1 {
      font-size: 56px;
      line-height: 140%;
      color: $dark-color;
      font-family: $primary-font-700;
      @include media991() {
        font-size: 26px;
      }
      .lasso-bg {
        position: relative;

        figure {
          position: absolute;
          left: -10px;
          right: -10px;
          top: 10px;
          max-width: 240px;
          pointer-events: none;
          user-select: none;
          @include media991() {
            left: -5px;
            right: -5px;
            top: 5px;
          }
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }

    &-details {
      position: relative;
      display: flex;
      margin: 20px 0 0 0;
      @include media991() {
        flex-direction: column;
        z-index: 55;
        margin: 50px 0 0 0;
      }

      .doctor-figure {
        min-width: 784px;
        max-width: 784px;
        max-height: 466px;
        position: relative;
        @include media991() {
          min-width: 100%;
          max-width: 100%;
          max-height: 100%;
        }

        img {
          position: relative;
          left: -110px;
          width: 100%;
          height: 100%;

          @include media991() {
            left: 0; // Corrected shorthand
            margin: 110px 0 0 0;
            transform: scale(1.4); // Use transform explicitly
          }

          @include media475() {
            transform: scale(1.4); // Add transform for consistency
          }
        }
      }

      &-flex {
        display: flex;

        .one {
          position: relative;
          background-color: $white-color;
          z-index: 55;
          padding: 24px;
          border-radius: 12px;
          max-width: 552px;
          @include media575() {
            max-width: 346px;
            margin-left: 20px;
            margin-top: -30px;
            padding: 10px 9px;
          }
          .details-wrap {
            display: flex;
            margin: 10px 0;
            @include media575() {
              margin: 0;
            }
            figure {
              min-width: 24px;
              max-width: 24px;
              height: 24px;
              margin-right: 15px;
              margin-top: 5px;
              img {
                width: 100%;
                height: 100%;
              }
            }
            p {
              font-size: 20px;
              line-height: 31px;
              color: $dark-color;
              @include media1199() {
                font-size: 16px;
              }
              @include media575() {
                font-size: 15px;
                line-height: 25px;
                margin: 5px 0;
              }
            }
          }
        }
      }
    }
  }
  .review_one {
    position: absolute;
    right: 100px;
    bottom: -10px;
    @include media991() {
      bottom: -120px;
      right: 10px;
    }
    .review-wrap {
      position: relative;
      padding: 12px 132px 12px 24px;
      border-radius: 12px;
      background-color: $white-color;
      @include media1199() {
        padding: 6px 132px 6px 12px;
      }
      @include media991() {
        padding: 12px 20px 12px 20px;
      }
      .small-flex {
        display: flex;
      }
    }
    .text-p {
      font-size: 19px;
      font-weight: 700;
      color: $dark-color;

      @include media991() {
        font-size: 16px;
      }
    }
    .img {
      position: absolute;
      top: -50px;
      right: -50px;
      @include media1199() {
        top: -20px;
      }
      @include media991() {
        top: -30px;
        right: -30px;
      }
      figure {
        max-width: 94px;
        height: 94px;
        @include media991() {
          max-width: 62px;
          height: 62px;
        }
        img {
          width: 100%;
          height: 100%;
          border: 1px solid $background-color;
          border-radius: 50px;
        }
      }
    }
  }
  &__call-details {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 55;
    @include media991() {
      position: relative;
      display: flex;
      flex-direction: row-reverse;
      justify-content: center;
      margin: 10px 0;
    }
    .call_number {
      font-size: 14px;
      padding: 7px 44px 7px 20px;
      line-height: 22px;
      border-radius: 6px 0 0 6px;
      background-color: $white-color;
      @include media991() {
        width: 100%;
        font-size: 12px;
        padding: 0 0 0 10px;
        margin-right: -20px;
      }
      .call-flex {
        display: flex;
        @include media767() {
          figure {
            max-width: 14px;
            max-height: 14px;

            img {
              width: 100%;
              height: 100%;
              margin-top: 5px;
            }
          }
        }
      }
    }

    .link-cta {
      background-color: $cta-color;
      width: 100%;
      text-decoration: none;
      margin-top: 11px;
      border: none;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 8px 15px 8px 20px;
      font-size: 20px;
      border-radius: 12px 0 0 0;
      cursor: pointer;
      color: $white-color;
      font-weight: 600;
      font-family: $primary-font;
      position: relative;
      line-height: 30px;
      &::after {
        width: 100%;
        height: 100%;
        position: absolute;
        background-color: $hover-cta;
        content: "";
        animation: scaleGlow 2s cubic-bezier(0.075, 0.82, 0.165, 1) infinite;
        z-index: -1;
        border-radius: 12px 0 0 0;
        @include media991() {
          border-radius: 100px;
        }
      }
      @keyframes scaleGlow {
        0% {
          transform: scale(1);
        }
        50% {
          transform: scaleX(1.1) scaleY(1.2);
        }
        100% {
          transform: scale(1.1);
          opacity: 0;
        }
      }
      @include media1199() {
        font-size: 2vw;
      }
      @include media991() {
        width: 100%;
        border-radius: 100px;
        font-size: 14px;
        text-align: left;
        padding: 0;
        margin-right: 14px;
        line-height: 22px;
      }
      figure {
        margin-right: 11px;
        display: flex;
        align-items: center;
        @include media575() {
          max-width: 15px;
          height: 15px;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
    span {
      @include media991() {
        display: block;
      }
    }
  }
  .wrap-two {
    margin-left: -450px;
    margin-top: 0px;
    z-index: 5;
    @include media991() {
      margin-left: 0;
    }
  }
  .correction {
    position: relative;
    margin-left: 55px;
    margin-top: 105px;
    background-color: $white-color;
    padding: 20px;
    border-radius: 20px;
    @include media1199() {
      margin-top: 90px;
    }
    @include media991() {
      margin-left: 0;
      margin-top: 20px;
      margin-bottom: 8px;
      padding: 20px 10px;
    }
    .details-wrap {
      display: flex;
      max-width: 605px;
      @include media1400() {
        max-width: 450px;
      }
      @include media1199() {
        max-width: 300px;
      }
      @include media991() {
        max-width: 100%;
      }
      figure {
        min-width: 85px;
        max-width: 85px;
        max-height: 85px;
        @include media1199() {
          min-width: 40px;
          max-width: 40px;
          max-height: 40px;
        }
        img {
          width: 100%;
          height: 100%;
        }
      }
      .customer_details {
        margin-left: 21px;
        @include media991() {
          margin-left: 12px;
        }
        p {
          line-height: 20px;
          color: $sub-dark-color;
          @include media575() {
            font-size: 12px;
          }
        }
        .small-flex {
          display: flex;
        }
        span {
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          @include media1199() {
            display: block;
          }
          @include media991() {
            display: block;
          }
          figure {
            max-width: 16px;
            min-width: 16px;
            height: 16px;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .h-flex {
            display: flex;
            @include media991() {
              margin: 7px 0 16px;
            }
          }
          .blue_tick {
            max-width: 11px;
            min-width: 11px;
          }
          .user_name {
            margin: 0 10px 0 30px;
            font-size: 14px;
            font-weight: 600;
            color: $dark-color;
            @include media1199() {
              margin: 0;
            }
            @include media991() {
              margin: 0 10px 0 0;
            }
            @include media575() {
              display: block;
            }
          }
          .small-flex-t {
            display: flex;
            @include media1199() {
              display: flex;
            }
          }
          .verified {
            margin-left: 5px;
            font-size: 12px;
          }
        }
      }
      .abs_img {
        pointer-events: none;
        user-select: none;
        position: absolute;
        top: -140px;
        right: -200px;
        z-index: -1;
        @include media991() {
          display: none;
        }
        figure {
          max-width: 262.03px;
          min-width: 262.03px;
        }
      }
    }
  }

  .absolute-header {
    position: absolute;
    bottom: -60px;
    font-size: 18px;
    background: $dark-color;
    padding: 6px 20px;
    border-radius: 12px;
    color: $white-color;
    font-family: $primary-font-700;
    left: 60px;
    text-transform: capitalize;
    @include media1199() {
      font-size: 20px;
      min-width: 450px;
      bottom: -55px;
    }
    @include media991() {
      display: none;
    }
  }
  .absolute-header-small {
    position: absolute;
    right: -20px;
    color: $white-color;
    font-weight: 700;

    @include media991() {
      background-color: $dark-color;
      font-size: 1.2vw;
      line-height: 15px;
      width: 65%;
      top: 65px;
      text-align: right;
      letter-spacing: 1px;
      z-index: -1;
    }
    @include media767() {
      font-size: 1.5vw;
      padding-right: 20px;

    }
    @include media575() {
      line-height: 2.609vw;
      top: 70px;
      font-size: 1.7vw;
    }
    @include media400() {
      top: 80px;
      font-size: 2vw;

    }
  }
  .wellhelpMobile {
    position: absolute;
    right: 0;
    top: 140px;
    max-width: 224px;
    line-height: 30px;
    font-size: 2vw;
    color: $white-color;
    .well-help-image {
      opacity: 0.5;
    }
    @include media991() {
      right: 0;
      font-size: 18px;
    }
    @include media767() {
      right: 0;
    }
    @include media575() {
      font-size: 14px;
      max-width: 180px;
      line-height: 22px;
    }
    @include media475() {
      font-size: 12px;
      max-width: 124px;
      line-height: 15px;
      top: 140px;
    }
    @include media400() {
      top: 120px;
    }
  }
  .two-div {
    max-width: 287px;
    margin-left: 41px;
    font-size: 20px;
    line-height: 140%;
    @include media1199() {
      font-size: 16px;
    }
    @include media991() {
      display: none;
    }
    img {
      margin-left: -10px;
    }
    .wellhelp {
      margin-top: -5px;
      color: $dark-color;
    }
  }
}
.td-n {
  text-decoration: none;
  color: $dark-color;
  font-weight: 600;
}
