@use "../../mixin.scss" as *;
@use "../../variable.scss" as *;

.announcement_bar {
  background-color: $primary-color;
  display: flex;
  justify-content: center;
  padding: 6.5px 0 8.5px;
  font-size: 14px;
  align-items: center;
  @include media575() {
    font-size: 12px;
  }
  figure {
    max-width: 22px;
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    margin: 0 4px 0 10px;
    @include media575() {
      max-width: 15px;
    }
    img {
      width: 100%;
      height: 100%;
    }
  }
  &-number {
    a {
      font-family: $primary-font-600;
      font-size: 16px;
      font-weight: 500;
      color: $black-color;
      text-decoration: none;
      @include media575() {
        font-size: 12px;
      }
    }
  }
}
