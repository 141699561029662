@use "./variable.scss" as *;
@use "./mixin.scss" as *;
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  font-family: $primary-font;
}

.desk-hide {
  @include media992-min() {
    display: none;
  }
}
.mob-hide {
  @include media991() {
    display: none !important;
  }
}
.small-flex {
  display: flex;

  figure {
    margin-right: 2px;
  }
}
.review-link {
  padding: 0 29.5px;
  position: relative;

  a {
    position: relative;

    width: 100%;
    display: flex;
    justify-content: center;
    font-size: 20px;
    background-color: $cta-color;
    text-decoration: none;
    color: $white-color;
    align-items: center;
    border-radius: 12px;
    font-weight: 700;
    padding: 3px 0;
    transition: 0.2s;
    z-index: 55;
    &::after {
      width: 100%;
      height: 100%;
      position: absolute;
      background-color: $cta-color;
      content: "";
      border-radius: 12px;
      z-index: -1;
      animation: scaleGlow 1.8s ease-in infinite;
      top: 0;
      left: 0;
    }
    &:hover {
      background-color: $hover-cta;
    }

    @keyframes scaleGlow {
      0% {
        transform: scale(1);
      }
      50% {
        transform: scaleX(1.1) scaleY(1.2);
      }
      100% {
        transform: scale(1.1);
        opacity: 0;
      }
    }
    @include media991() {
      font-size: 18px;
      padding: 6px 0;
    }
    figure {
      max-width: 22px;
      display: flex;
      align-items: center;
      margin: 0 11px 0 0;
      @include media991() {
        max-width: 15px;
      }
      img {
        width: 100%;
        height: auto;
      }
    }
  }
}
.text-with-border {
  padding: 0px;
  position: relative;
  &__content {
    position: relative;
    padding-left: 40px;
    @include media991() {
      padding-left: 12px;
    }
    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      width: 8px;
      background: $cta-color;
      border-radius: 4px;
      @include media991() {
        width: 4px;
      }
    }
  }

  &__title {
    font-size: 40px;
    font-family: $primary-font-700;
    color: $dark-color;
    margin: 0;
    line-height: 40px;
    text-transform: capitalize;
    @include media991() {
      font-size: 22px;
      line-height: 30px;
    }
  }

  &__subtitle {
    font-size: 20px;
    font-weight: 600;
    color: $sub-dark-color;
    margin-top: 8px;
    margin-bottom: 0;
    @include media991() {
      font-size: 14px;
    }
  }
}
