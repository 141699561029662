@use "../../mixin.scss" as *;
@use "../../variable.scss" as *;

.three-cards {
  background-color: $primary-color;
  padding: 65px 0 51px;
  &__flex {
    display: flex;
    justify-content: space-between;
    @include media991() {
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
    }
    &-card {
      border: 1px solid $border-blue;
      position: relative;
      max-width: 387px;
      background-color: $white-color;
      text-align: center;
      padding: 20px 10px;
      border-radius: 12px;
      @include media991() {
        min-width: 512px;
        padding: 20px 0;
      }
      @include media767() {
        min-width: 0;
        max-width: 387px;
      }

      &:nth-child(2) {
        margin: 0 40px;
        @include media991() {
          margin: 42px 0 42px 0px;
        }
      }
      figure {
        position: absolute;
        top: -30px;
        left: -30px;
        background-color: $white-color;
        border: 1px solid $border-blue;
        border-radius: 8px;
        width: 60px;
        height: 60px;
        padding: 8.5px;
        min-width: 44px;
        display: flex;
        align-items: center;
        @include media991() {
          width: 46px;
          height: 46px;
          left: -20px;
        }
        @include media575() {
          left: -10px;
        }
        img {
          width: 100%;
        }
      }

      .texts {
        h3 {
          font-size: 18px;
          color: $dark-color;
          font-family: $primary-font-700;
        }
        p {
          font-size: 16px;
          color: $sub-dark-color;
          @include media991() {
            line-height: 20px;
            font-size: 14px;
          }
        }
      }
    }
  }
}
