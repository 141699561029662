$primary-font: Cairo;
$primary-font-600: CairoSemiBold;
$primary-font-700: CairoBold;
$secondary-font: Poppins;
$primary-color: #9ddff8;
$dark-color: #202020;
$sub-dark-color: #3f3f3f;
$black-color: #000000;
$white-color: #ffffff;
$background-color: #faf8f4;
$cta-color: #f58612;
$hover-cta: #bc6102;
$header-font-color: #1682fc;
$primary-gradient: linear-gradient(97.26deg, #9ddff8 -96.21%, #f5ffff 150.24%);
$secondary-gradient: linear-gradient(122.4deg, #f79936 1.14%, #f07f0a 110.76%);
$border-gradient: linear-gradient(97.26deg, #10a7e0 -96.21%, #f5ffff 150.24%);
$border-color: #f2f2f2;
$border-blue: #08779b;
$last-section-gradient: linear-gradient(
  0deg,
  rgba(226, 247, 255, 1) 0%,
  rgba(255, 255, 255, 1) 30%,
  rgba(247, 253, 255, 1) 70%,
  rgba(226, 247, 255, 1) 100%
);
