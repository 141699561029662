@font-face {
  font-family: Cairo;
  src: url("./Fonts/Cairo-Regular.woff");
}
@font-face {
  font-family: CairoSemiBold;
  src: url("./Fonts/Cairo-SemiBold.woff");
}
@font-face {
  font-family: CairoBold;
  src: url("./Fonts/Cairo-Bold.woff");
}
