@use "../../mixin.scss" as *;
@use "../../variable.scss" as *;

.four-points {
  background: $primary-gradient;
  padding: 32px 0;
  &__details {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 20px 30px;
    @include media991() {
      grid-template-columns: repeat(1, 1fr);
    }
    &-card {
      max-width: 593px;
      display: flex;
      align-items: center;
      background: $white-color;
      padding: 14px 0 14px 14.85px;
      border-radius: 6px;
      @include media575() {
        padding: 5.5px 0 5.5px 14px;
      }
      figure {
        max-width: 22px;
        height: 22px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      p {
        margin-left: 15px;
        font-weight: bold;
        color: $sub-dark-color;
        @include media575() {
          font-size: 14px;
          line-height: 23px;
        }
      }
      @include media991() {
        max-width: 100%;
      }
    }
  }
}
