@use "../../variable.scss" as *;
@use "../../mixin.scss" as *;

.choose-us {
  background-color: $background-color;
  &__title {
    text-align: center;
    background-color: $dark-color;
    padding: 17.97px 0;
    color: $white-color;
    h1 {
      font-size: 43px;
      line-height: 140%;
      @include media767() {
        font-size: 22px;
        line-height: 120%;
      }
    }
    p {
      line-height: 140%;
      font-size: 24px;
      @include media767() {
        font-size: 14px;
        line-height: 120%;
      }
    }
  }

  .wrapper {
    display: flex;
    padding: 64px 0;
    @include media991() {
      padding: 24px 0;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
  &__credit-card {
    max-width: 439px;
    margin-right: 44px;
    @include media991() {
      margin-right: 0;
      max-width: 525px;
    }
    figure {
      max-width: 525px;
      img {
        width: 100%;
      }
    }
    p {
      margin: 37px 0 40px;
      font-size: 22px;
      line-height: 32px;
      color: $dark-color;
      @include media991() {
        font-size: 14px;
        margin: 20px 0;
        line-height: 130%;
      }
    }
    .review-link {
      padding: 0;
      margin: 0 24px 0 0;
      @include media991() {
        margin-right: 0;
      }
      a {
        padding: 8px 0;
      }
    }
  }
  &__list {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    @include media991() {
      margin-top: 30px;
      max-width: 525px;
    }
    &-card {
      position: relative;
      padding: 6px 24px;
      background-color: $white-color;
      margin-bottom: 30px;
      max-width: 683px;
      min-height: 98px;
      border-radius: 12px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      @include media1250() {
        max-width: 600px;
      }
      @include media1199() {
        max-width: 500px;
      }
      @include media991() {
        max-height: 111px;
        padding: 16px;
      }
      &:last-child {
        margin-bottom: 0;
      }
      h3 {
        font-size: 20px;
        letter-spacing: 2%;
        color: $dark-color;
        font-family: $primary-font-700;
        @include media991() {
          font-size: 16px;
        }
      }
      p {
        font-size: 16px;
        line-height: 140%;
        margin-top: 4px;
        color: $sub-dark-color;
        @include media991() {
          font-size: 14px;
        }
      }
      figure {
        position: absolute;
        right: -40px;
        top: -20px;
        max-width: 80px;
        @include media1199() {
          max-width: 500px;
          right: -5px;
          top: -30px;
        }
        @include media575() {
          max-width: 40px;
          top: -20px;
          right: -10px;
        }
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
