@use "../../mixin.scss" as *;
@use "../../variable.scss" as *;

.here-to-help {
  padding: 72px 0 63px;

  .wrapper {
    display: flex;
    justify-content: space-between;
    @include media991() {
      flex-direction: column;
      align-items: center;
    }
  }
  &__section-first {
    max-width: 679px;
  }
  h2 {
    color: $dark-color;
    font-family: $primary-font-700;
    @include media1199() {
      font-size: 1.8vw;
    }
    @include media991() {
      font-size: 18px;
      text-wrap: balance;
    }
  }
  .emt-border {
    margin: 53px 0;
    height: 1.5px;
    width: 100%;
    background-color: $border-color;
    @include media991() {
      margin: 24px 0 0 0;
      opacity: 0;
    }
  }
  .section-first-details {
    position: relative;
    display: flex;
    @include media991() {
      flex-direction: column;
      align-items: center;
    }
    margin-top: 42px;
    .neat-pick {
      max-width: 525px;
      height: 298px;
      @include media991() {
        position: relative;
        width: 100%;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 16px;
      }
      span {
        position: absolute;
        bottom: 60px;
        left: 0;
        font-size: 20px;
        background-color: $white-color;
        padding: 9px 14px;
        border-radius: 0 5px 5px 0;
        line-height: 20px;
        color: $dark-color;
        font-family: $primary-font-700;
        @include media1199() {
          font-size: 1vw;
        }
        @include media991() {
          font-size: 20px;
        }
      }
    }
    .texts {
      padding: 27px 0 27px 30px;
      font-size: 20px;
      max-width: 372px;
      @include media1199() {
        font-size: 1.5vw;
        max-width: 300px;
      }
      @include media991() {
        font-size: 18px;
        max-width: 525px;
        padding: 27px 0;
      }
      @include media575() {
        font-size: 14px;
      }
      color: $dark-color;
      p {
        line-height: 140%;
      }
      ul {
        margin-top: 20px;
      }
    }
  }

  &__section-second {
    max-width: 525px;

    @include media1199() {
      max-width: 400px;
      margin-left: 20px;
    }
    @include media991() {
      max-width: 525px;
      margin-left: 0;
    }
    .delivery-bag {
      max-width: 525px;
      position: relative;
      @include media991() {
        max-width: 525px;
        min-height: 300px;
      }
      img {
        width: 100%;
        height: 300px;
        object-fit: cover;
        border-radius: 16px;
      }
      span {
        position: absolute;
        bottom: 30px;
        left: 0;
        font-size: 20px;
        background-color: $white-color;
        padding: 9px 14px;
        font-weight: 700;
        border-radius: 0 5px 5px 0;
        line-height: 20px;
        color: $dark-color;
        font-family: $primary-font-700;

        @include media1199() {
          font-size: 1vw;
        }
        @include media991() {
          font-size: 20px;
        }
      }
    }

    .aside-details {
      font-size: 20px;
      color: $dark-color;
      @include media1199() {
        font-size: 1.5vw;
      }
      @include media991() {
        font-size: 18px;
        max-width: 525px;
      }
      @include media575() {
        font-size: 14px;
        max-width: 400px;
      }
      p {
        line-height: 140%;
      }
      ul {
        margin: 20px 0;
      }
    }
  }
  ul {
    list-style: none;
    li {
      display: flex;
      align-items: flex-start;

      figure {
        margin-right: 15px;
        max-width: 18px;
        height: 18px;
        img {
          width: 100%;
        }
      }
    }
  }
}
