@use "../../mixin.scss" as *;
@use "../../variable.scss" as *;

.terms-and-conditions {
  padding: 40px 0;

  p {
    line-height: 28px;
  }
}
