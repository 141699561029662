@use "../../mixin.scss" as *;
@use "../../variable.scss" as *;

.see-if-qualify {
  padding: 23.5px 0;
  background-color: $dark-color;
  color: $white-color;
  border-radius: 10.78px;
  @include media991() {
    border-radius: 0;
  }
  &__wrap {
    display: flex;
    @include media991() {
      display: block;
    }
    div p {
      font-size: 24px;
      line-height: 140%;
      @include media575() {
        font-size: 14px;
        line-height: 25px;
      }
    }
    .second-text {
      margin-left: 60px;
      @include media991() {
        margin-left: 0;
        margin-top: 8px;
      }
    }
    div p span {
      position: relative;
      color: $sub-dark-color;
      z-index: 5;
      font-size: 18px;
      font-weight: 700;
      &:nth-child(2) {
        display: inline-block;
      }
      &::after {
        content: "";
        position: absolute;
        top: 2px;
        left: 0;
        width: 100%;
        height: 90%;
        border-radius: 2px;
        z-index: -1;
        background-color: $white-color;
      }
    }
  }

  &__button {
    button {
      color: $white-color;
      font-family: $primary-font-700;
      display: block;
      background: none;
      border: none;
      margin: 0 auto;
      font-size: 30px;
      margin-top: 16px;
      cursor: text;
      @include media575() {
        font-size: 18px;
      }
    }
  }
}
