@use "../../mixin.scss" as *;
@use "../../variable.scss" as *;

.spending-allowance {
  padding: 70px 0;
  background-color: $background-color;

  &__details {
    padding-left: 39px;
    margin-top: 79px;
    display: flex;
    justify-content: space-between;
    @include media991() {
      flex-direction: column;
      align-items: center;
    }
    @include media575() {
      padding-left: 0px;
    }
  }
  &__wrap {
    width: 525px;
    margin-left: 30px;
    @include media991() {
      max-width: 525px;
      width: 100%;
      margin-left: 10px;
    }
  }
  &__sub {
    position: relative;
    margin-bottom: 50px;
    background: $white-color;
    border-radius: 12px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .tut-icon {
    position: absolute;
    left: -30px;
    top: -30px;
    @include media991() {
      left: -20px;
      top: -20px;
    }
    figure {
      max-width: 60px;
      max-height: 60px;
      @include media991() {
        max-width: 43px;
        max-height: 43px;
      }
      img {
        width: 100%;
      }
    }
  }
  .text {
    padding: 17px 24px 17px 50px;
    @include media991() {
      padding: 17px 24px 17px 30px;
    }
    h3 {
      font-size: 22px;
      color: $dark-color;
      @include media991() {
        font-size: 16px;
      }
    }
    p {
      font-size: 20px;
      line-height: 25px;
      color: $sub-dark-color;
      @include media991() {
        font-size: 14px;
        line-height: 24px;
        margin-top: 6px;
      }
    }
  }
  .step {
    position: absolute;
    top: -25px;
    right: -20px;
    background-color: $primary-color;
    padding: 1px 25px;
    font-size: 20px;
    font-weight: 700;
    border-radius: 4px;
    @include media991() {
      right: 20px;
      font-size: 14px;
      top: -17px;
    }
  }
  &__orange {
    border: 2px solid $cta-color;
    padding: 30px;
    border-radius: 20px;
    margin-left: 30px;
    width: 540px;
    @include media991() {
      width: 100%;
      max-width: 510px;
      padding: 17px;
      margin-left: 0;
      margin-top: 26px;
    }
  }
  &__card-main {
    position: relative;
    max-width: 510px;
    background: $secondary-gradient;
    border-radius: 20px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    padding: 0 61px;
    @include media1199() {
      padding: 0 35px;
    }
    @include media991() {
      padding: 0 18px;
    }
  }
  .text-card {
    position: relative;
    padding: 10px 22px;
    background-color: $white-color;
    border-radius: 12px;
    border: 5px solid #f9b976;
    z-index: 5;
    @include media991() {
      padding: 14px;
      margin-top: 70px;
    }
    figure {
      position: absolute;
      top: -70px;
      left: -40px;
      max-width: 80px;
      height: 80px;
      @include media991() {
        max-width: 47px;
        height: 47px;
        top: -30px;
        left: -10px;
      }
      img {
        width: 100%;
        height: 100%;
      }
    }
    p {
      font-size: 19px;
      line-height: 25px;

      @include media991() {
        font-size: 14px;
      }
    }
  }
  .cart {
    position: absolute;
    bottom: -70px;
    right: -70px;
  }
  .text-top {
    margin-top: 40px;
    .mt-txt {
      margin-top: 10px;
      font-size: 15px;
    }
    p {
      font-size: 15px;
      @include media1250() {
        font-size: 1.2vw;
      }
      @include media991() {
        font-size: 14px;
      }
    }
  }
  .cta-top {
    top: 10px !important;
  }
  .cta-button,
  .cta-top {
    position: relative;
    top: 68px;
    margin: 0 auto;
    z-index: 5;
    height: 65px;
    @include media991() {
      width: 100%;
    }
    .one {
      display: flex;
      width: 100%;
    }
    @include media991() {
      top: 0;
      margin-top: 29px;
      margin-bottom: 41px;
    }
    button {
      cursor: pointer;
      color: $cta-color;
      text-decoration: none;
      font-family: $primary-font;
      font-weight: 700;
      font-size: 26px;
      border: none;
      background-color: $white-color;
      padding: 8px 27px;
      border-radius: 12px;
      transition: 0.3s;
      @include media1199() {
        font-size: 15px;
        padding: 5px 24px;
      }
      &:hover {
        background-color: #e7e7e7;
      }
      @include media991() {
        width: 100%;
      }
    }
    .margin-button {
      padding: 0 27px;
      margin-top: 10px;
      margin-right: 10px;
      @include media1199() {
        margin-right: 5px;
        padding: 0;
      }
      &:last-child {
        margin-right: 0;
      }

      a {
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        color: $cta-color;
        padding: 9px;
        font-size: 16px;
        figure {
          max-width: 20px;
          display: flex;
          align-items: center;
          margin-right: 5px;
          img {
            width: 100%;
            height: auto;
          }
        }
      }
    }
  }
}
