@mixin media350 {
  @media only screen and (max-width: 350px) {
    @content;
  }
}
@mixin media375 {
  @media only screen and (max-width: 375px) {
    @content;
  }
}
@mixin media400 {
  @media only screen and (max-width: 400px) {
    @content;
  }
}
@mixin media475 {
  @media only screen and (max-width: 475px) {
    @content;
  }
}
@mixin media575 {
  @media only screen and (max-width: 575px) {
    @content;
  }
}
@mixin media767 {
  @media only screen and (max-width: 767px) {
    @content;
  }
}
@mixin media768-min {
  @media only screen and (min-width: 768px) {
    @content;
  }
}

@mixin media991 {
  @media only screen and (max-width: 991px) {
    @content;
  }
}
@mixin media1199 {
  @media only screen and (max-width: 1199px) {
    @content;
  }
}
@mixin media1250 {
  @media only screen and (max-width: 1250px) {
    @content;
  }
}
@mixin media992-min {
  @media only screen and (min-width: 992px) {
    @content;
  }
}
@mixin media992to1130 {
  @media only screen and (max-width: 1130px) and (min-width: 992px) {
    @content;
  }
}
@mixin media991to768 {
  @media only screen and (max-width: 991px) and (min-width: 768px) {
    @content;
  }
}
@mixin media576to767 {
  @media only screen and (max-width: 767px) and (min-width: 576px) {
    @content;
  }
}

@mixin media1400 {
  @media only screen and (max-width: 1400px) {
    @content;
  }
}
@mixin media1500 {
  @media only screen and (max-width: 1500px) {
    @content;
  }
}

@mixin media1700 {
  @media only screen and (max-width: 1700px) {
    @content;
  }
}

@mixin media1200-min {
  @media only screen and (min-width: 1200px) {
    @content;
  }
}

@mixin media1280-min {
  @media only screen and (min-width: 1280px) {
    @content;
  }
}
@mixin media1280to992 {
  @media only screen and (max-width: 1280px) and (min-width: 992px) {
    @content;
  }
}

@mixin media1400-min {
  @media only screen and (min-width: 1400px) {
    @content;
  }
}

@mixin media1600-min {
  @media only screen and (min-width: 1600px) {
    @content;
  }
}
.container {
  max-width: 1284px;
  width: 100%;
  padding-right: 20px;
  padding-left: 20px;
  margin: 0 auto;
}
