@use "../../mixin.scss" as *;
@use "../../variable.scss" as *;

@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
.help-with-groc {
  background: $primary-gradient;
  .container {
    padding: 0;
  }
  .variety-plans__header {
    p {
      font-size: 24px;
      text-align: center;
      line-height: 40px;
      @include media575() {
        font-size: 18px;
        line-height: 20px;
      }
      &:nth-child(2) {
        font-size: 16px;
        line-height: 20px;
      }
    }
  }
  .no-pad {
    @include media991() {
      padding: 10px 12px;
    }
  }

  &__desk {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    &-card {
      position: relative;
      text-align: center;
      background-color: $white-color;
      margin: 20px 10px -0px 10px;
      overflow: hidden;
      max-width: 190px;
      min-width: 190px;
      border-radius: 6px 6px 0 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 16px 0 18px 0;
      @include media991() {
        margin-bottom: -10px;
      }
      @include media575() {
        min-width: 175px;
        max-width: 175px;
      }
      .border-top {
        width: 100%;
        height: 20px;
        background: linear-gradient(97.26deg, #10a7e0 -96.21%, #f5ffff 150.24%);
        position: absolute;
        top: 0;
      }
      figure {
        max-width: 90px;
        height: 90px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 20px auto 0 auto;
        background-color: $white-color;
        img {
          width: 60px;
          height: 60px;
        }
      }
      .p {
        line-height: 26px;
        font-size: 20px;
        font-weight: 700;
        @include media991() {
          font-size: 16px;
        }
      }
    }
  }
}
