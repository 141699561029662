@use "../../mixin.scss" as *;
@use "../../variable.scss" as *;

.variety-plans {
  background-color: $primary-color;
  padding-bottom: 34px;
  @include media991() {
    padding-bottom: 30px;
  }
  .container {
    @include media575() {
      padding: 0;
    }
  }
  &__header {
    width: max-content;
    background-color: $dark-color;
    margin: 0 auto;
    padding: 10px 40px;
    color: $white-color;
    font-size: 24px;
    letter-spacing: 0.2px;
    border-radius: 0 0 10px 10px;
    @include media991() {
      width: 100%;
      text-align: center;
    }
    @include media767() {
      font-size: 18px;
      padding: 10px 24px;
      line-height: 20px;
    }
    @include media575() {
      padding: 10px 20px;
      max-width: 343px;
    }
  }
  &__company {
    margin-top: 26px;
    @include media575() {
      padding: 0 22px;
    }
    .company-card {
      display: flex;
      justify-content: space-between;
      @include media991() {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(3, 1fr);
        justify-items: center;
        gap: 14px 14px;
      }
      .company-fig {
        width: 195px;
        height: 72px;
        margin: 0 6px;
        background-color: $white-color;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 6px;
        @include media1500() {
          padding: 0 20px;
        }
        @include media991() {
          width: 100%;
        }
        figure {
          display: flex;
          align-items: center;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}
