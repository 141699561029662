@use "../../mixin.scss" as *;
@use "../../variable.scss" as *;

.footer-comp {
  position: relative;
  @include media991() {
    padding-top: 20px;
    background-color: $background-color;
  }
  &__company {
    position: relative;
    z-index: 55;
    background-color: $white-color;
    border-radius: 12px;
    padding: 24px 42px;
    min-width: 1132px;
    margin-bottom: -70px;
    @include media1199() {
      min-width: 900px;
    }
    @include media991() {
      min-width: 100%;
      position: relative;
      padding: 0;
      margin-bottom: 40px;
      background-color: #ffffff00;
    }

    &-flex-offered {
      display: flex;
      justify-content: space-between;
      align-items: center;
      @include media991() {
        flex-direction: column;
        align-items: flex-start;
      }
      h2 {
        font-size: 36px;
        font-family: $primary-font-700;
        color: $dark-color;
        text-transform: capitalize;
        @include media1199() {
          font-size: 26px;
        }
        @include media991() {
          font-size: 22px;
          padding-right: 20px;
          line-height: 120%;
        }
      }

      &-timing {
        display: flex;
        @include media991() {
          margin: 11px 0 0px;
          align-items: center;
        }
        figure {
          max-width: 40px;
          margin-right: 15px;
          @include media991() {
            max-width: 25px;
            margin-right: 7px;
          }
          img {
            width: 100%;
          }
        }
        .timing p {
          line-height: 120%;
          font-size: 18px;
          letter-spacing: 2%;
          @include media991() {
            font-size: 11px;
          }
        }
      }
    }
    .footer__company-offered-timing {
      display: flex;
    }

    .company-card-1 {
      margin-top: 24px;
      display: flex;
      justify-content: space-between;
      @include media991() {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(3, 1fr);
        justify-items: center;
        gap: 11px;
        div {
          width: 100%;
          height: 58px;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: $white-color;
          border-radius: 4.83px;
        }
      }
      img {
        height: 31px;
      }
    }
  }
}
